import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import { FaBullseye } from 'react-icons/fa';
import api from '../../api/api';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const C2pModal = (props) => {
    const [show, setShow] = useState(false);
    const cookies = new Cookies();
    const dispatch = useDispatch()
    const cart = useSelector(state => (state.cart))
    const address = useSelector((state) => state.address)
    const [loadingPay, setloadingPay] = useState(false)

    console.log(props);
    

    function getLibrary(amount,eject) {
      
     // console.log(eject);
      // const callback = (response) => {
      //   // Los valores de la respuesta son: 
      //   // ok: boolean, (true en caso de ser exitosa, false en caso de ser fallida)
      //   // description: string, (descripción de la transacción)
      //   // transactionId: string, (referencia bancaria de la transacción)
      //   // Ejemplo: { ok: true, description: 'Pago exitoso', transactionId: '1858749961512' }
      // };
     
      return new Promise((resolve, reject) => {
        const script = document.createElement('script'); 
        script.id = 'source-script';
        script.src = `https://puntoyapos.com.ve/pos/assets/scripts/py-script.js`; 
        script.async = true;
       
        //script.onload = ()  => {    
        script.onload = function () {
          if(!eject){

            window.addEventListener('click', function(e) {
              //if(e.currentTarget.value('py-close-1')){
                /// console.log("Hizo click dentro funcion");

                // console.log(props.paymentUrls);
             // }else{
              //   console.log("Hizo click  fuea entro");
              // }
               
              //getLibrary(props.amount); 
                
            });

            window.payWithPuntoYa(amount,function(result){
              console.log("entra a funcion");

             // props.setpaymentUrls(false);

              console.log(props.paymentUrls);

                window.addEventListener('click', function(e){
                  // if (document.getElementById('py-close-1').contains(e.target)){
                  //   alert("Clicked in Box");
                  // } else{
                  //   alert("Clicked outside Box");
                  // }
                })

              if(result.ok===false){
                 console.log(result);
                // let transactionId =result.transactionId;
                 let transactionId ='222222';
                 let paymentMethod= "C2P";

                  api.placeOrder(cookies.get('jwt_token'), cart.checkout.product_variant_id,
                   cart.checkout.quantity, cart.checkout.sub_total, 
                   cart.checkout.delivery_charge.total_delivery_charge, 
                   cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount, 
                   paymentMethod, address.selected_address.id, props.deliveryTime, cart.promo_code?.id)
                    .then(response => response.json())
                    .then(async result => {
                        if (result.status === 1) {
                          let orderId=result.data.order_id;
                          console.log(cookies.get('jwt_token')+"/"+orderId+"/"+transactionId+"/"+paymentMethod);

                          await api.addTransaction(cookies.get('jwt_token'), orderId, transactionId, paymentMethod)
                          .then(response => response.json())
                          .then(result => {
                              if (result.status === 1) {
                                  //setShow(true)
                                  //setIsOrderPlaced(true)
                                  //setloadingPay(false)
                                  console.log("Agrego Transacc");
                              }
                              else {
                                console.log("NO Agrego Transacc");
                                  //setloadingPay(false)
                              }
                              //closeModal.current.click()
                          })
                          .catch(error => console.log(error))
                    
                        }
                        else {
                            toast.error(result.message)
                            //setloadingPlaceOrder(false)
                        }
                    })
                    .catch(error => console.log(error))

              }
             

            });
    
            console.log('eject 1');
          }else{
          //  window.payWithPuntoYa(amount,callback);

            console.log('eject 0');
          }
    
       }
        
       document.body.appendChild(script);
       return() => {
        document.body.removeChild(script);

       };
        //document.body.removeChild(script);
        
      })
    }

    // document.getElementById("py-close-2").addEventListener('click', function() {
    //   console.log("Cerro la ventana");
    //   //getLibrary(props.amount);
        
    // });

  //   window.onbeforeunload = function (e) {
  //     var e = e || window.event;
  //     if (e) {
  //         e.returnValue = alert('Estás por cerrar la página, cuidado!');
  //     }
  // }

    window.addEventListener('load', function() {
      console.log("Cargando archivo Eventlistener");
    });

  useEffect(() => {
    console.log(loadingPay);
    //setloadingPay(false);
   // setShow(true);
   console.log(loadingPay);
    getLibrary(props.amount,show);
  
    console.log('Carga de archivo por click');
    //props.setpaymentUrls(false);

    return ()=>  //setloadingPay(props.paymentUrls);
    console.log('UNMONTING');
}, [ props.paymentUrls])


}


export default function InjectC2p(props) {
  return (
          <>
              <C2pModal expectedDate={props.expectedDate} paymentUrls={props.paymentUrls} clicks={props.clicks} orderID={props.orderID} amount={props.amount} deliveryTime={props.deliveryTime}
               ></C2pModal>
          </>
  )
}
