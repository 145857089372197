import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import { FaBullseye } from 'react-icons/fa';
import api from '../../api/api';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button, Modal } from 'react-bootstrap';
import animate1 from '../../utils/order_placed_back_animation.json'
import animate2 from '../../utils/order_success_tick_animation.json'
import Lottie from 'lottie-react';

import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaListAlt, FaHome, FaEdit } from 'react-icons/fa'
import { IoIosImage } from "react-icons/io";
import { ActionTypes } from '../../model/action-type';

//import { useForm } from 'react-hook-form';


const PagomModal = (props) => {

  const dispatch = useDispatch()
  const cart = useSelector(state => (state.cart))
  const address = useSelector((state) => state.address)
  

const cookies = new Cookies();
const navigate = useNavigate();

const [startDate, setStartDate] = useState(new Date());
const maxdate =new Date();


// const {register, handleSubmit } = useFormAction();
const [pagomDetails, setpagomDetails] = useState({
  emisor: '',
  receptor: '',
  date_pay: '',
  refer: '',
  file: ''
})

const [show, setShow] = useState(true);


const { t } = useTranslation()
const closeModal = useRef();

const [isOrderPlaced, setIsOrderPlaced] = useState(false);
const [loadingPay, setloadingPay] = useState(false);
const [selectedFile, setselectedFile] = useState()

const handleSubmit = async (event) => {
  event.preventDefault();
  setloadingPay(true);
  let paymentMethod= "PagoM";
  let transactionId =pagomDetails.refer;
  let valid=1;
  let file=pagomDetails.refer;
  const allowedTypes = ["image/jpeg","image/jpg", "image/gif"];


  if(pagomDetails.file){
    if (!allowedTypes.includes(pagomDetails.file?.type)) {

   // if (!pagomDetails.file === 'image/png' || !pagomDetails.file.type === 'image/jpg' || !pagomDetails.file.type === 'image/jpeg') {
        valid=0;
        toast.error(t("file_type_not_allowed"))
        setloadingPay(false)
    }
  }

  if(valid===1){
    api.placeOrder(cookies.get('jwt_token'), cart.checkout.product_variant_id,
      cart.checkout.quantity, cart.checkout.sub_total, 
      cart.checkout.delivery_charge.total_delivery_charge, 
      cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount, 
      paymentMethod, address.selected_address.id, props.deliveryTime, cart.promo_code?.id)
      .then(response => response.json())
      .then(async result => {
          if (result.status === 1) {
            let orderId=result.data.order_id;
           // console.log(cookies.get('jwt_token')+"/"+orderId+"/"+transactionId+"/"+paymentMethod);

            await api.addTransaction(cookies.get('jwt_token'), orderId, transactionId, paymentMethod,pagomDetails.file)
            .then(response => response.json())
            .then(result => {
              //console.log(result)
                if (result.status === 1) {
                    //setShow(true)
                    setIsOrderPlaced(true)
                    //setloadingPay(false)
                    //console.log("Agrego Transacc");
                }
                else {
                  console.log("NO Agrego Transacc");
                    //setloadingPay(false)
                }
                //closeModal.current.click()
            })
            .catch(error => console.log(error))
      
          }
          else {
              toast.error(result.message)
              //setloadingPlaceOrder(false)
          }
      })
      .catch(error => console.log(error))

  }
 
  
}

const handleClose = () => {

  api.removeCart(cookies.get('jwt_token')).then(response => response.json())
  .then(async (result) => {
      if (result.status === 1) {
          dispatch({ type: ActionTypes.SET_CART, payload: null })
          dispatch({ type: ActionTypes.SET_CART_CHECKOUT, payload: null })
      }
  })


  setShow(false)
 // props.setShow(false)
  navigate('/')

    //props.setisLoader(true);
};

   return (
        <>
         {isOrderPlaced ?
         <>
          <Modal
              show={show}
              onHide={handleClose}
              keyboard={true}
              className='success_modal'>

              <Lottie className='lottie-content' animationData={animate1} loop={true}></Lottie>
              <Modal.Header closeButton className='flex-column-reverse success_header'>
                  <Modal.Title><Lottie animationData={animate2} loop={true}></Lottie></Modal.Title>
              </Modal.Header>
              <Modal.Body className='success_body'>
              {t("order_placed_description")}
              </Modal.Body>
              <Modal.Footer className="success_footer">
                  <Button variant="primary" onClick={handleClose} className='checkout_btn'>
                  {t("go_to_home")}
                  </Button>

              </Modal.Footer>
          </Modal>
          </>
          : null}

          <div className="modal-body">

          <div className='stripe-container d-flex flex-column p-0'>

              <div className="d-flex flex-row justify-content-between header">
                  <span className='heading'>Pago Móvil</span>
                  <button type="button" className="close-stripe" data-bs-dismiss="modal" 
                   aria-label="Close" ref={closeModal} onClick={() => {
                   // props.setShow(false);
                   props.setshow(false);
                   props.setisLoader(true);
                 
                    //setisloading(false)
                  
                   }}
                   ><AiOutlineCloseCircle /></button>
              </div>
              
              <form onSubmit={handleSubmit} id="stripe-form" >

                <div class="row ">
                    <div class="col-xl-6 col-md-5 col-sm-4">
                       <div class="row text-end ">
                          <div class=" col-12  ">
                             <button type="button" class="btn btn-outline-primary btn-lg text-right">Transferencia</button>
                          </div> 
                      </div> 
                      <div class="row">
                            <div class="pull-left col-8">
                            <label>Seleccione el banco donde quieres transferir</label>
                                <select className="form-select" required onChange={(e) => {
                                setpagomDetails(state => ({ ...state, emisor: e.target.value }));
                                }}>
                                  <option value="">Seleccione</option>
                                  <option value="1">Banesco</option>
                                  <option value="2">Venezuela</option>
                                </select>
                                
                            </div> 
                      </div> 

                      <div class="row">
                          <div class="pull-left col-8">
                              <label> Banco emisor</label>
                              <select className="form-select" required onChange={(e) => {
                                setpagomDetails(state => ({ ...state, receptor: e.target.value }));
                                }}>
                                <option value="">Seleccione</option>
                                <option value="1">Banco Venezuela</option>
                                <option value="2">Banesco</option>
                              </select>
                          </div> 
                      </div>   

                      <div class="row">
                          <div class="pull-left col-md-4">
                            <label>Monto a Pagar</label>
                            <input type='text' className=" form-control" value={props.amount} readOnly="true"></input>
                          </div> 

                          <div class="pull-left col-md-8 customDatePickerWidth">
                            <label for="fechapago">Fecha de Pago </label>   <br />
                            {/* <DatePicker id="fechapago" required 
                              className='form-control'
                              selected={startDate}
                              selectsRange
                              startDate={startDate}
                              mask='dd/mm/yyyy'
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setStartDate(date)}/> */}
                                <DatePicker
                                      className='form-control'
                                      dateFormat="dd/MM/yyyy"
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      mask='dd/mm/yyyy'
                                      maxDate={maxdate}
                                      
                                />

                          </div>
                      </div>   

                      
                      <div class="row">
                        <div class="pull-left col-md-8" >
                        <label>Código de referencia</label>
                        {/* <TextField id="standard-basic" label="Standard" /> */}
                        <input type='text' className="form-control" onChange={(e) => {
                          setpagomDetails(state => ({ ...state, refer: e.target.value }));
                          }} required></input>
                        </div> 
                      </div>   


                    </div>
                    <div class="col-xl-6 col-md-5 col-sm-4">
                      <div class="row ">
                          <div class=" col-12  ">
                             <button type="button" class="btn btn-outline-primary btn-lg">Pago Móvil</button>
                          </div> 
                      </div> 
                      <br />
                      <div class="row">
                        <div class="pull-left col-8">
                          <label>Número de Celular</label> <br />
                          <label><strong>0412-9507029</strong></label>  
                        </div> 
                      </div> 
                      <br />
                      <div class="row">
                        <div class="pull-left col-8">
                          <label>Nombre o Razón Social</label> <br />
                          <label><strong>Francel Ramírez</strong></label>  
                        </div> 
                      </div> 
                      <br />
                      <div class="row">
                        <div class="pull-left col-8">
                          <label>CI o Rif</label> <br />
                          <label><strong>V-16877754</strong></label>  
                        </div> 
                      </div> 
                      <br />
                    </div>
                </div>

                  <div class="row ">
                    <div class="col text-end">
                      <label htmlFor="file">
                        <span className="badge-img"><IoIosImage size={70} fill='var(--secondary-color)' /></span>
                      </label>

                        <input type="file" id="file" name='file' onChange={(e) => { setpagomDetails(state => ({ ...state,file: e.target.files[0]})) }} accept='image/png, image/jpeg, image/jpg' />
                    </div>
                  
                    <div class="col">
                          <label>
                          <span>Comprobante de Pago</span>
                          </label>
                      <br />

                      <label>
                          <span>Adjunte archivo con extensión JPG,JPEG,PNG o PDF</span>
                      </label>
                    </div>
                  </div>
                   

                

                  {loadingPay
                      ? <Loader screen='full' background='none' />
                      :
                      <button whileTap={{ scale: 0.1 }} type='submit' className='pay-stripe'>{t("pay")}</button>
                  }
                 
              </form>
          </div>

          </div>

      
    </>
    )
    
}


export default function InjectPagom(props) {
  return (
          <>
              <PagomModal show={props.show} setshow={props.setShow} 
               isLoader={props.isLoader} setisLoader={props.setisLoader}  
              expectedDate={props.expectedDate} paymentUrls={props.paymentUrls} 
              clicks={props.clicks} orderID={props.orderID} amount={props.amount} 
              deliveryTime={props.deliveryTime}
               ></PagomModal>
          </>
  )
}
