import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import api from '../../api/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const Cod = async (props) => {


   // const dispatch = useDispatch();
    
    const cart = useSelector((state) => state.cart);
    const address = useSelector((state) => state.address);
    const cookies = new Cookies();

    const {t} = useTranslation();

    //const [paymentMethod, setpaymentMethod] = useState("COD");

    //let methodPayment="COD";

    await api.placeOrder(cookies.get('jwt_token'), cart.checkout.product_variant_id, cart.checkout.quantity, 
    cart.checkout.sub_total, cart.checkout.delivery_charge.total_delivery_charge, 
    cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount, 
    props.paymentMethod, address.selected_address.id, props.delivery_time, cart.promo_code?.id)
    .then(response => response.json())
    .then(async (result) => {
        props.setisLoader(false)
        if (result.status === 1) {
            toast.success(t("order_successfully_placed"));
            props.setloadingPlaceOrder(false)

            props.setIsOrderPlaced(true);
            props.setShow(true)
        }
        else {
            toast.error(result.message)
            props.setloadingPlaceOrder(false)
        }
    })
    .catch(error => {
        props.setisLoader(false)
        props.setloadingPlaceOrder(false)
        console.log(error)
    })
}
