import React, { useEffect, useState } from 'react'
import { RiCoupon2Fill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes } from '../../model/action-type'
import { toast } from 'react-toastify'


const Coupon = (props) => {

const dispatch = useDispatch()
const cart = useSelector(state => (state.cart)) 
const setting = useSelector(state => (state.setting))

const { t } = useTranslation()

    return (
        <>
         <div className="promo-section">

                <div className="heading">
                    <span>{t("coupon")}</span>
                </div>
                <div className="promo-wrapper">
                    <div className="promo-container">
                        <div className="promo-button d-block d-lg-flex">
                            <span className="">{t("have_coupon")}</span>
                            <button className="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#promooffcanvas" 
                                    aria-controls="promooffcanvaslabel">
                                {t("view_coupon")}
                            </button>
                        </div>
                        {cart.cart && cart.promo_code ?
                            <>
                                <div className="promo-code">
                                    <div className="">
                                        <span><RiCoupon2Fill size={26} fill='var(--secondary-color)' /></span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className='promo-name'>{cart.promo_code.promo_code}</span>
                                        <span className='promo-discount-amount'>{cart.promo_code.message}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span>{setting.setting && setting.setting.currency} {cart.promo_code.discount}</span>
                                        <span className='promo-remove' onClick={() => {
                                            dispatch({ type: ActionTypes.SET_CART_PROMO, payload: null });
                                        toast.info(t("coupon_removed"))
                                        }}> {t("remove")}</span>
                                    </div>
                                </div>
                            </>
                            : <></>}
                    </div>
                </div>

                </div>

        
        </>
        
    )
}
export default Coupon