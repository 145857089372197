import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import { Calendar } from 'react-calendar'
import coverImg from '../../utils/cover-img.jpg'
import Address from '../address/Address'
import PayMethod from './PayMethod'
import Coupon from './Coupon'
import './checkout.css'
import 'react-calendar/dist/Calendar.css'
import api from '../../api/api'

import { useDispatch, useSelector } from 'react-redux'
import paypal from "../../utils/ic_paypal.svg"
import { FaRupeeSign } from "react-icons/fa";
import Cookies from 'universal-cookie'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Modal from 'react-bootstrap/Modal';

//lottie animation JSONs
import Lottie, { useLottie } from 'lottie-react';
import animate1 from '../../utils/order_placed_back_animation.json'
import animate2 from '../../utils/order_success_tick_animation.json'

//payment methods
// import CheckoutForm from './CheckoutForm'

import Loader from '../loader/Loader'
import { Button } from 'react-bootstrap'
import { ActionTypes } from '../../model/action-type';
import { RiCoupon2Fill } from 'react-icons/ri'
import Promo from '../cart/Promo'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { useTranslation } from 'react-i18next'

import { Cod } from '../payment/Cod'

import InjectC2p from './C2pModal'
import InjectPagom from './PagomModal'
import {Helmet} from "react-helmet"


const Checkout = () => {
    const dispatch = useDispatch()
    const city = useSelector(state => state.city);
    const cart = useSelector(state => (state.cart))
    const address = useSelector((state) => state.address)
    const user = useSelector(state => (state.user))
    const setting = useSelector(state => (state.setting))
    const [paymentUrl, setpaymentUrl] = useState(null)

    const [paymentUrls, setpaymentUrls] = useState(null)

    const paypalStatus = useRef(false);

    const cookies = new Cookies();
    const navigate = useNavigate()

    const [c2pOrderId, setc2pOrderId] = useState(null)
    const [pagomOrderId, setpagomOrderId] = useState(null)

    const [IsOrderPlaced, setIsOrderPlaced] = useState(false);

    const [clicks, setClicks] = useState(true);

    //console.log(address.selected_address.latitude);
    //console.log(address.selected_address.latitude+"/"+address.selected_address.longitude)

    useEffect(() => {

        //SEARCH ADDRES DEFAULT
        api.getCart(cookies.get('jwt_token'),city.city.latitude, city.city.longitude, 1)
        .then(response => response.json())
        .then(result => {
    
            if (result.status === 1) {
                dispatch({ type: ActionTypes.SET_CART_CHECKOUT, payload: result.data })
               // alert("1 uno");
            }else{
                alert("2 dos");
            }

            //setdeliveryCharge (cart.checkout.delivery_charge.total_delivery_charge);

        })
        .catch(error => console.log(error))
        fetchTimeSlot()
    }, [])

    useEffect(() => {

        const handleMessage = (event) => {
            if (event.origin === api.getAppUrl()) {
                if (event.data === "success") {
                    paypalStatus.current = true;
                    setShow(true);
                    setIsOrderPlaced(true);
                } else {
                    api.deleteOrder(cookies.get('jwt_token'), orderID)
                    toast.error(t("payment_failed"))
                }
            }
        };

        window.addEventListener('message', handleMessage);
        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };

    }, [window])

    const [expectedDate, setexpectedDate] = useState(new Date())

    const [timeslots, settimeslots] = useState(null)
    const [selectedAddress, setselectedAddress] = useState(null)
    const [selectedPayMethod, setselectedPayMethod] = useState(null)
 
    const [expectedTime, setexpectedTime] = useState(null)
    const [paymentMethod, setpaymentMethod] = useState("COD")
    const [deliveryTime, setDeliveryTime] = useState("")
    const [orderID, setOrderID] = useState(0)
    const [loadingPlaceOrder, setloadingPlaceOrder] = useState(false)
  
    const [show, setShow] = useState(false);
    // const [paymentSettings, setpaymentSettings] = useState(null)
    const [isLoader, setisLoader] = useState(false)

    const [pagomModalShow, setpagomModalShow] = useState(false)

    const fetchTimeSlot = () => {
        api.fetchTimeSlot()
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    settimeslots(result.data)
                    //setexpectedTime(result.data.time_slots[0])

                    setexpectedTime(result.data.time_slots.filter((element) => checkLastOrderTime(element.last_order_time))[0])
                    
                }
            })
            .catch(error => console.log(error))

    }

    // Filter the time slots based on last_order_time
   
    useEffect(() => {
       // const currentDateTime = new Date();
        //setexpectedDate(new Date(currentDateTime.setDate(currentDateTime.getDate() + (Number(timeslots?.time_slots_delivery_starts_from) - 1))))
    }, [timeslots])

    useEffect(() => {
        setexpectedTime(timeslots?.time_slots.filter((element) => checkLastOrderTime(element.last_order_time))[0])
    }, [expectedDate])

    const checkLastOrderTime = (lastTime) => {
        const currentTime = new Date(expectedDate)

        if(currentTime > new Date()) {
            return true
        }
        console.log(expectedDate)
        const hours = lastTime.split(':')[0]
        const minutes = lastTime.split(':')[1]
        const seconds = lastTime.split(':')[2]

        const lastOrderTime = new Date();

        lastOrderTime.setHours(hours)
        lastOrderTime.setMinutes(minutes)
        lastOrderTime.setSeconds(seconds)

        return currentTime <= lastOrderTime

    };


    const HandlePlaceOrder = async (e) => {
        // e.preventDefault();

        if (!expectedTime) {
            toast.error(t('please_select_time_slots'))
        }
        else if (!expectedDate) {
        toast.error(t('please_select_date'))
        }
        else if (!address.address) {
            toast.error(t('please_add_an_address'))
        }
        else if (address.selected_address === null) {
            toast.error(t('please_select_delivery_address'))
        }
        else{
            setDeliveryTime(`${expectedDate.getDate()}-${expectedDate.getMonth() + 1}-${expectedDate.getFullYear()} ${expectedTime.title}`)
            const delivery_time = `${expectedDate.getDate()}-${expectedDate.getMonth() + 1}-${expectedDate.getFullYear()} ${expectedTime.title}`;
            
            setloadingPlaceOrder(true)
           
            if (delivery_time === null) {
                toast.error(t("please_select_preffered_delivery_time"))
            }
            else if (paymentMethod === 'COD') {
             
                await api.placeOrder(cookies.get('jwt_token'), cart.checkout.product_variant_id, cart.checkout.quantity, 
                cart.checkout.sub_total, cart.checkout.delivery_charge.total_delivery_charge, 
                cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount, 
                paymentMethod, address.selected_address.id, delivery_time, cart.promo_code?.id)
                .then(response => response.json())
                .then(async (result) => {
                    setisLoader(false)
                    if (result.status === 1) {
                        toast.success(t("order_placed_description"))
                        setloadingPlaceOrder(false)
            
                        setIsOrderPlaced(true);
                        setShow(true)
                    }
                    else {
                        toast.error(result.message)
                        setloadingPlaceOrder(false)
                    }
                })
                .catch(error => {
                    setisLoader(false)
                    setloadingPlaceOrder(false)
                    console.log(error)
                })

            }
            else if (paymentMethod === 'C2P') {

                setc2pOrderId(1563)
                setloadingPlaceOrder(false)
                
                // // place order
                // await api.placeOrder(cookies.get('jwt_token'), cart.checkout.product_variant_id, cart.checkout.quantity, cart.checkout.sub_total, cart.checkout.delivery_charge.total_delivery_charge, cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount, paymentMethod, address.selected_address.id, delivery_time, cart.promo_code?.id)
                //     .then(response => response.json())
                //     .then(async result => {
                //         if (result.status === 1) {
                //             setc2pOrderId(result.data.order_id)

                //             //DELETE ORDER 
                //             api.deleteOrder(cookies.get('jwt_token'), result.data.order_id)
                    
                //         }
                //         else {
                //             toast.error(result.message)
                //             setloadingPlaceOrder(false)
                //         }
                //     })
                //     .catch(error => console.log(error))
                    
               
            } 
            else if (paymentMethod === 'PagoM') {
                setpagomOrderId(1563)
                setpagomModalShow(true)
                setloadingPlaceOrder(false)
               
            }
            
            else if (paymentMethod === 'Paypal') {
                await api.placeOrder(cookies.get('jwt_token'), cart.checkout.product_variant_id, cart.checkout.quantity, cart.checkout.sub_total, cart.checkout.delivery_charge.total_delivery_charge, cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount, paymentMethod, address.selected_address.id, delivery_time, cart.promo_code?.id)
                    .then(response => response.json())
                    .then(async result => {
                        setOrderID(result.data.order_id)
                        // fetchOrders();
                        if (result.status === 1) {
                            await api.initiate_transaction(cookies.get('jwt_token'), result.data.order_id, "Paypal")
                                .then(resp => resp.json())
                                .then(res => {
                                    // console.log(res.data.paypal_redirect_url)
                                    setisLoader(false)

                                    if (res.status === 1) {
                                        setloadingPlaceOrder(false)
                                        setpaymentUrl(res.data.paypal_redirect_url);
                                        // window.open(res.data.paypal_redirect_url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                                        // document.getElementById("iframe_id").contentWindow.location.href
                                        // handleRozarpayyPayment(result.data.order_id, res.data.transaction_id, cart.checkout.total_amount, user.user.name, user.user.email, user.user.mobile, setting.setting.app_name);

                                        var ccavenue_redirect_url = res.data.paypal_redirect_url;
                                        //var ccavenue_redirect_url = "https://admin.pocketgroceries.in/customer/ccavenue_payment";

                                        var subWindow = window.open(ccavenue_redirect_url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                                        var redirect_url = res.data.paypal_redirect_url
                                        /*subWindow.postMessage('Hello from parent window!', '*');
                                        console.log("redirect_url : ",redirect_url);*/
                                        const checkChildWindow = setInterval((e) => {
                                            if (subWindow && subWindow.closed) {
                                                clearInterval(checkChildWindow);
                                                console.log('Child window is closed');
                                                if (subWindow && subWindow.closed && !paypalStatus.current) {
                                                    api.deleteOrder(cookies.get('jwt_token'), result.data.order_id)
                                                    toast.error("Payment failed ");
                                                    // Perform any actions or display a message here
                                                }

                                            }
                                        }, 1500); // Adjust the interval (in milliseconds) as needed

                                    } else {
                                        toast.error(res.message)
                                        setloadingPlaceOrder(false)
                                    }
                                })
                                .catch(error => console.error(error))

                        }
                        else {
                            toast.error(result.message)
                            setloadingPlaceOrder(false)
                        }
                    })
                    .catch(error => console.log(error))
            }
           
        }

    }

    const handleClose = () => {
        setisLoader(true)
        api.removeCart(cookies.get('jwt_token')).then(response => response.json())
            .then(async (result) => {
                if (result.status === 1) {
                    dispatch({ type: ActionTypes.SET_CART, payload: null })
                    dispatch({ type: ActionTypes.SET_CART_CHECKOUT, payload: null })
                }
            })
        setShow(false)
        paypalStatus.current = false;
        navigate('/')
    };


    useEffect(() => {
        if (IsOrderPlaced) {
            setShow(true);
            setTimeout(async () => {
                handleClose();
            }, 5000);
        }
    }, [IsOrderPlaced]);

    useEffect(()=>{
       
        // if (address.address && !address.selected_address) {
        //     console.log("PAsA AQUI USEEFFECT");
        //     dispatch({type:ActionTypes.SET_SELECTED_ADDRESS, payload:address.address.find((element)=>element.is_default == 1 )})
        //    console.log(address.selected_address.latitude+"/"+address.selected_address.longitude)
        // }else{

        // console.log("Actualiza ADDRESS")
        //console.log(address.selected_address.latitude+"/"+address.selected_address.longitude)
           
            api.getCart(cookies.get('jwt_token'),address.selected_address.latitude, address.selected_address.longitude, 1)
            .then(response => response.json())
            .then(result => {
        
                if (result.status === 1) {
                    dispatch({ type: ActionTypes.SET_CART_CHECKOUT, payload: result.data })
                   // alert("1 uno");
                }else{
                    alert("2 dos");
                }
    
                //setdeliveryCharge (cart.checkout.delivery_charge.total_delivery_charge);
    
            })
            .catch(error => console.log(error))

        //}
    },[address])

    
    const { t } = useTranslation()
    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo
    }
    const current = new Date()
    return (
        <>
            <section id='checkout'>
                {/* {console.log(IsOrderPlaced)} */}
                {IsOrderPlaced ?

                    <>
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={true}
                            className='success_modal'
                        >
                            <Lottie className='lottie-content' animationData={animate1} loop={true}></Lottie>
                            <Modal.Header closeButton className='flex-column-reverse success_header'>
                                <Modal.Title><Lottie animationData={animate2} loop={false}></Lottie></Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='success_body'>
                                {t("order_placed_description")}
                            </Modal.Body>
                            <Modal.Footer className="success_footer">
                                <Button variant="primary" onClick={handleClose} className='checkout_btn'>
                                    {t("go_to_home")}
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </>
                    : null}
                {/* //     {stripepayment ? <PaymentElement /> : null} */}
                <div className='cover'>
                    <img src={coverImg} onError={placeHolderImage} className='img-fluid' alt="cover"></img>
                    <div className='title'>
                        <h3>{t("checkout")}</h3>
                        <span><Link to="" className="text-white text-decoration-none">{t("home")} / </Link> </span><span className='active'>{t("checkout")}</span>
                    </div>
                </div>



                {
                    setting.payment_setting === null && expectedTime === null
                        ? (
                            <Loader screen='full' />
                        )
                        : (
                            <>
                                <div className='checkout-container container'>

                                    <div className='checkout-util-container col-lg-9'>
                                        <div className='billibg-address-wrapper checkout-component'>
                                            <span className='heading'>{t("billing_details")}</span>

                                            <Address setselectedAddress={setselectedAddress} selectedAddress={selectedAddress} />
                                        </div>
                                        {timeslots && timeslots.time_slots_is_enabled === "true" ?
                                            <>
                                                <div className='delivery-day-wrapper checkout-component'>
                                                    <span className='heading'>{t("prefered_day")}</span>
                                                    <div className='d-flex justify-content-center p-3'>
                                                        <Calendar value={expectedDate} onChange={(e) => {
                                                            if (new Date(e) >= new Date()) {
                                                                setexpectedDate(new Date(e))
                                                            }
                                                            else if (new Date(e).getDate() === new Date().getDate() && new Date(e).getMonth() === new Date().getMonth() && new Date(e).getFullYear() === new Date().getFullYear()) {
                                                                setexpectedDate(new Date(e))
                                                            }
                                                            else {
                                                                toast.info(t("please_select_valid_delivery_day"))
                                                            }
                                                        }}
                                                            calendarType={"US"}
                                                            minDate={new Date(current.setDate(current.getDate() + (Number(timeslots?.time_slots_delivery_starts_from) - 1)))}
                                                            maxDate={new Date(current.setDate(current.getDate() + (Number(timeslots?.time_slots_allowed_days - 1))))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='delivery-time-wrapper checkout-component'>
                                                    <span className='heading'>{t("prefered_time")}</span>
                                                    <div className='d-flex p-3' style={{ flexWrap: "wrap" }}>
                                                        {timeslots === null
                                                            ? <Loader screen='full' />

                                                            : (
                                                                <>

                                                                    {timeslots.time_slots.map((timeslot, index) => {
                                                                        if (!checkLastOrderTime(timeslot.last_order_time)) {
                                                                            return null
                                                                        }else {

                                                                            return (
                                                                                
                                                                                <div key={index} className='time-slot-container'>
                                                                                <div>
                                                                                    <input type="radio" name="TimeSlotRadio" id={`TimeSlotRadioId${index}`} defaultChecked={index === 0 ? true : false} onChange={() => {
                                                                                        setexpectedTime(timeslot);
                                                                                    }} />
                                                                                </div>
                                                                                <div>

                                                                                    {timeslot.title}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    })}
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            : <></>}

                                    </div >

                                    <div className='order-container'>
                                        <Coupon/>
                                    
                                        <PayMethod setpaymentMethod={setpaymentMethod}  paymentMethod={paymentMethod}
                                        show={show} setshow={setShow} isLoader={isLoader} setisLoader={setisLoader} />

                                        <div className='order-summary-wrapper checkout-component'>

                                            <div className="order-bill">
                                                <div className='heading'>{t("order_summary")}</div>

                                                <div className='order-details'>
                                                    {cart.checkout === null || user.user === null
                                                        ? (
                                                            <Loader screen='full' />

                                                        )
                                                        : (
                                                            <div className='summary'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>{t("sub_total")}</span>
                                                                    <div className='d-flex align-items-center'>

                                                                        <span>
                                                                            {setting.setting && setting.setting.currency}   
                                                                            {((cart.checkout.sub_total)*setting.setting.tasadolar).toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex justify-content-between'>
                                                                    <span>{t("delivery_charge")}</span>
                                                                    <div className='d-flex align-items-center'>

                                                                        <span>
                                                                            {setting.setting && setting.setting.currency}  
                                                                            {((cart.checkout.delivery_charge.total_delivery_charge)*setting.setting.tasadolar).toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                    </div>
                                                                </div>
                                                                {cart.promo_code && <>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <span>{t("discount")}</span>
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>- 
                                                                                {setting.setting && setting.setting.currency}    
                                                                                {(Number(cart.promo_code?.discount)*setting.setting.tasadolar).toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                        </div>
                                                                    </div>
                                                                </>}
                                                                <div className='d-flex justify-content-between total'>
                                                                    <span>{t("total")}</span>
                                                                    <div className='d-flex align-items-center total-amount' style={{ color: "var(--secondary-color)" }}>

                                                                        {cart.promo_code ?
                                                                            <span>
                                                                                {setting.setting && setting.setting.currency} 
                                                                                {((cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge)*setting.setting.tasadolar   ).toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                            : <>
                                                                                <span>
                                                                                {setting.setting && setting.setting.currency} 
                                                                                {((cart.checkout.total_amount)*setting.setting.tasadolar).toFixed(setting.setting && setting.setting.decimal_point)}</span>
                                                                            </>}
                                                                    </div>
                                                                </div>


                                                                {loadingPlaceOrder
                                                                    ?
                                                                    <Loader screen='full' background='none' />
                                                                    : <>
                                                                        <div className='button-container'>

                                                                            {paymentMethod === "Stripe"
                                                                                ? <motion.button whileTap={{ scale: 0.8 }} type='button' className='checkout' onClick={(e) => { e.preventDefault(); HandlePlaceOrder() }} id='stripe-toggle' data-bs-toggle="modal" data-bs-target="#stripeModal">{t("place_order")}</motion.button>
                                                                                : <motion.button whileTap={{ scale: 0.8 }} type='button' className='checkout' onClick={(e) => { e.preventDefault(); HandlePlaceOrder(); setpaymentUrls(true); }}>{t("place_order")}</motion.button>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }

                                                            </div>)}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div >
                            </>
                        )
                }


                <Promo />

            </section >

            <div className="modal fade" id="c2pModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="c2pModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{ minWidth: "100%" }}>

                        {c2pOrderId === null 
                            ? <Loader width='100%' height='100%' />
                            :
                            
                            <InjectC2p expectedDate={expectedDate} deliveryTime={deliveryTime} clicks={clicks} orderID={c2pOrderId} 
                            amount={cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount}
                            paymentUrls={paymentUrls} setpaymentUrls={setpaymentUrls}
                            />
                        }
                        
                        { <Helmet><meta name="py-client" content="B2E78EF256E4FAAD" /> </Helmet> }


                        {/* {pagomOrderId === null 
                            ? <Loader width='100%' height='100%' />
                            :
                            
                            <InjectPagom expectedDate={expectedDate} deliveryTime={deliveryTime} clicks={clicks} orderID={c2pOrderId} 
                            amount={cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount}
                            paymentUrls={paymentUrls} setpaymentUrls={setpaymentUrls}
                            />
                        } */}

                    </div>
                </div>
            </div>

            <Modal id="pagomModal" size='xl' centered show={pagomModalShow}>
                    <Modal.Body>

                    {pagomOrderId === null 
                        ? <Loader width='100%' height='100%' />
                        :
                        <InjectPagom setShow={setpagomModalShow} show={pagomModalShow} 
                        isLoader={isLoader} setisLoader={setisLoader}
                        amount={cart.promo_code ? (cart.promo_code.discounted_amount + cart.checkout.delivery_charge.total_delivery_charge) : cart.checkout.total_amount}
                        deliveryTime={deliveryTime} />
                    }

                </Modal.Body>
            </Modal>

        </>
    )
}
export default Checkout