import React, { useEffect, useState } from 'react'
import paypal from "../../utils/ic_paypal.svg"
import codss from '../../utils/ic_cod.svg'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
//import Cod from '../payment/Cod';
//import C2p from '../payment/C2p';
//import Paypal from '../payment/Paypal';

const PayMethod = (props) => {
const dispatch = useDispatch()
const setting = useSelector(state => (state.setting))    

const { t } = useTranslation()
    return (
        <>
        <div className='payment-wrapper checkout-component'>
            <span className='heading'>{t("payment_method")}</span>
            {setting.payment_setting.cod_payment_method === "1"   
            ? (     
                <>
                    <label className="form-check-label" htmlFor='cod'>
                        <div >
                            <div className='payment-selector'>
                                <img src={codss} alt='cod' />
                                <span>{t("cash_on_delivery")}</span>
                            </div>
                            <input type="radio" name="payment-method" id='cod' defaultChecked={true} onChange={() => {
                                props.setpaymentMethod("COD")
                            }} />
                        </div>
                    </label>

                    <label className="form-check-label" htmlFor='c2p'>
                    <div className='payment-selector'>
                        <div className="">
                            <img src={codss} alt='cod' />
                            <span>{t("C2P")}</span>
                        </div>
                        <input type="radio" name="payment-method" id='c2p' defaultChecked={false} onChange={() => {
                            props.setpaymentMethod("C2P")
                        }} />
                    </div>
                    </label>

                    <label className="form-check-label" htmlFor='pagom'>
                    <div className='payment-selector'>
                        <div className="">
                            <img src={codss} alt='cod' />
                            <span>{t("pago_movil")}</span>
                        </div>
                        <input type="radio" name="payment-method" id='pagom' defaultChecked={false} onChange={() => {
                            props.setpaymentMethod("PagoM")
                        }} />
                    </div>
                    </label>

                    </>     
            ) : null}

            {setting.payment_setting.paypal_payment_method === "1"
            ? (
                <>
                    <label className="form-check-label" htmlFor='paypal'>
                        <div className='payment-selector'>
                            <div className="">
                                <img src={paypal} alt='paypal' />
                                <span>{t("paypal")}</span>
                            </div>
                            <input type="radio" name="payment-method" id='paypal' onChange={() => {
                                props.setpaymentMethod("Paypal")
                            }} />
                        </div>
                    </label>
                </>
            ) : null}
        </div>
        
        </>
        
    )
}
export default PayMethod